import React from 'react'
import styled from 'styled-components'
import { Link } from 'prismic-reactjs'
import { resolve } from '../routingTables'
import { Link as RouterLink } from "react-router-dom";

const Container = styled.div`
  display:block;  
  max-width: 500px;
  margin: 3.5em auto;
  position: relative;
  text-align: center;
`

interface Props {
  link?: Link,
  children: React.ReactNode
}

const Decoration = ({ link, children}: Props) => {
  const content = <Container>{children}</Container>
  const linkTo = resolve(link)
  if (linkTo) {
    return <RouterLink to={linkTo}>{content}</RouterLink>
  } else {
    return content
  }
}

export default Decoration
