import React from 'react'
import { Document } from '@prismicio/client/types/documents'
import Quote from './Quote'
import Photo from './Photo'
import Text from './Text'
import Decoration from './Decoration'

const PageLinks = ({ items }: any) => {    
  return items.map((data: any, index: number) => <Photo text={data.title} src={data.photo.url} link={data.page} />)
}

const Decorative = ({ image }: any) => {
  console.log("he", image.url)
  return <Decoration>
    <svg height="3em" width="100%">      
      <image width="100%" height="100%" preserveAspectRatio="contain" href={image.url} />      
    </svg>  
    </Decoration>
}

const sliceComponents: Record<string, React.FunctionComponent<any>> = {  
  page_link: (props: Record<string, any>) => <PageLinks {...props} />,
  body_text: (props: Record<string, any>) => <Text richText={props.primary.contents} />,
  quote: (props: Record<string, any>) => <Quote {...props.primary} />,
  photo: (props: Record<string, any>) => <Photo src={props.primary.image.url} />,
  decoration: (props: Record<string, any>) => <Decorative image={props.primary.image} />,
}

const Slices = ({ slices }: any) => {
  const contents = slices.map((item: any, index: number) => {
    const Component = sliceComponents[item.slice_type]
    if (!Component) {
      console.warn("Unused content", item.slice_type, item)
      return null
    }
    console.log(item)
    return <Component key={index} {...item} />
  })

  return contents
}

interface Props {
  document: Document
}

const Content = ({ document }: Props) => {
  const {slices, body } = document.data 
  console.log(document)
  return <Slices slices={slices || body || []} />  
}

export default Content