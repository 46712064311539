import { Link } from 'prismic-reactjs'

export const linkResolver = (doc: any) => {  
  console.log("resolv", doc)
  return `/${doc.uid}`
}

export const resolve = (link?: Link) => {
  if (!link) return undefined
  switch (link.link_type) {
    case 'Document': return linkResolver(link)
    default: console.warn("Unhandled link type", link.link_type)
  }
}
