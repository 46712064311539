import React from 'react'
import styled from 'styled-components'
import { RichText, RichTextBlock, Link } from 'prismic-reactjs'
import { resolve } from '../routingTables'
import { Link as RouterLink } from "react-router-dom";

const Container = styled.div`
  display:block;  
  max-width: 500px;
  margin: 1em auto;
  position: relative;
  img {
    transition: padding 0.4s ease-in-out;
    box-sizing: border-box;
    width: 100%;
    padding: 6% 7.0% 6.5%;
    background: white;        
  }  
`

const OverlayElement = styled.div`
  position: absolute;
  overflow: hidden;
  top: 6%;
  left: 7%;
  right: 7%;
  bottom: 12%;    
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  box-sizing: border-box;
  padding: 2em;

  line-height: 110%;
  text-shadow: 0 0 25px hsla(0, 50%, 20%, 0.8);
  * {
    font-family: Raleway;
    font-weight: 400;
    color: white;
    font-size: 2.1em;
    line-height: 1.1em;
    text-transform: uppercase;

    @media (max-width: 414px) { font-size: 1.8em; }
    @media (max-width: 375px) { font-size: 1.6em; }
    @media (max-width: 320px) { font-size: 1.4em; }
  }
`

interface OverlayProps {
  linkTo?: string | object,
  children: React.ReactNode
}

const Overlay = ({ linkTo, children}: OverlayProps) => {
  const overlay = <OverlayElement>{children}</OverlayElement>
  if (linkTo) {
    return <RouterLink to={linkTo}>{overlay}</RouterLink>
  } else {
    return overlay
  }
}

interface Props {
  src: string,
  text?: RichTextBlock[]
  link?: Link
}

const Photo = ({ src, text, link }: Props ) => {    
  return <Container>
    <img src={src} alt="" />
    <Overlay linkTo={resolve(link)}>{RichText.render(text || [])}</Overlay>
  </Container>
}

export default Photo
