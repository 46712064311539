import React from 'react'
import styled from 'styled-components'
import { RichText, RichTextBlock } from 'prismic-reactjs'
import { linkResolver } from '../routingTables'

const Container = styled.div`
  margin: 5em auto;
  max-width: 450px;
  
  & > blockquote {
    font-size: 1.1em;    
    margin: 0.5em 1.5em;
    text-align: center;
    font-style: oblique;

    p { 
      line-height: 1.4em; 
      margin: 1em 0;
    }

    p:before {
      content: "“";
      opacity: 0.8;
      font-family: serif;
      font-size: 1.4em;
      vertical-align: text-top;
    }

    p:after {
      content: "”";
      opacity: 0.8;
      font-family: serif;
      font-size: 1.4em;
      vertical-align: text-top;
    }
  }
  
  & > p {
    font-size: 0.9em;
    text-align: right;
    margin: 0 0.5em;

    &:before {
      content: "—";
    }
  }
`

interface Props {
  quote: RichTextBlock[]
  attribution: string
}

const Quote = ({ quote, attribution }: Props) => {  
  return <Container>
    <blockquote>
      <RichText linkResolver={linkResolver} render={quote} />
    </blockquote>
    <p>{attribution}</p>
  </Container>
}

export default Quote