import React from 'react'
import { RichText, RichTextBlock } from 'prismic-reactjs'
import { linkResolver } from '../routingTables'

interface Props {
  richText?: RichTextBlock[]
}

const Text = ({ richText }: Props) => { 
  return <RichText linkResolver={linkResolver} render={richText} />
}

export default Text