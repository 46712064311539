import React from 'react';
import { ReactComponent as Logotype } from '../assets/logotype.svg';
import { Link } from "react-router-dom";
import styled from 'styled-components';

const Container = styled.header`
  text-align: center;
  color: #C85948;
  margin: 4em auto;
  width: 96%;
  a { color: unset; }

  .logo {
    margin: 0 0.2em;
    svg {
      width: 100%;
      max-width: 580px;
      height: auto;
    }
    a {
      text-decoration: none;
      color: white;
    }
  }

  h2 {
    margin-top: 0.5em;
    color: black;
    margin: 0;
  }
`

const Header = () => <Container>
  <div className="logo"><Link to="/"><Logotype /></Link></div>
  <h2>Christie Jordan Barron SEP, MSEd, CYT</h2>
</Container>

export default Header