import React from 'react';
import Root, { useKernel } from './Root';
import PrismicPreview from './PrismicPreview';
import styled from 'styled-components';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Header from './components/Header'
import Footer from './components/Footer'
import GlobalStyle from './components/GlobalStyle'
import Content from './components/Content'


const Container = styled.div`  
  margin: 2em auto;

  article {
    width: 90%;
    max-width: 620px;
    margin: auto;
  }
  
`

const Page = ({ children }: { children: React.ReactNode }) => <Container>
    <Header />
    {children}
    <Footer />
</Container>

const LoadingPage = () => <></>


const NotFoundPage = () => <Page>
  <h1>404 - Not Found</h1>
</Page>


const SimpleContentPage = ({ match }: any) => {
  const { prismic } = useKernel()
  const [doc, setDocData] = React.useState<any>(null)
  React.useEffect(() => {
    const fetchData = async () => {
    try {      
      const result = await prismic.getByUID('simplecontent', match.params.uid, {})
      setDocData(result)
    } catch (error) {
      console.error("Fetch data", error)      
    }    
    }
    fetchData()
  }, [])

  if (doc === undefined) return <NotFoundPage />
  if (!doc) return <LoadingPage />

  return <Page>
    <article>
      <Content document={doc} />      
    </article>
  </Page>
}

const HomePage = () => {
  const { prismic } = useKernel()
  const [doc, setDocData] = React.useState<any>(null)
  React.useEffect(() => {
    const fetchData = async () => {
    try {      
      const result = await prismic.getSingle('homepage', {})      
      setDocData(result)
    } catch (error) {
      console.error("Fetch data", error)      
    }    
    }
    fetchData()
  }, [])

  if (doc === undefined) return <NotFoundPage />
  if (!doc) return <LoadingPage />
  
  return <Page>
    <article>
      <Content document={doc} />              
    </article>
  </Page>
}

const App = () => {
  
  return (
    <Root config={{ prismicAPIEndpoint: 'https://interweave.cdn.prismic.io/api/v2' }}>
    <Container>
      <GlobalStyle />
      <Router>
      <Switch>
        <Route exact path="/preview" component={PrismicPreview} />
        <Route path="/:uid" component={SimpleContentPage} />
        <Route exact path="/" component={HomePage} />
        <Route component={NotFoundPage} />
      </Switch>
      </Router>
    </Container>
    </Root>
  );
}

export default App;
