import React, { useContext, useEffect, useState } from 'react'
import Prismic from '@prismicio/client'

export interface Configuration {
  prismicAPIEndpoint: string
  prismicAccessToken?: string
}

class Bootstrapper {
  
  readonly prismic: ReturnType<typeof Prismic.client>

  constructor(config: Configuration) {
    this.prismic = Prismic.client(config.prismicAPIEndpoint, { accessToken: config.prismicAccessToken })
    console.log("up")
  }

  destructor = () => {
    console.log("down")
  }

}

const Context = React.createContext<Bootstrapper>({} as Bootstrapper)

interface RootProps {
  config: Configuration
  children: React.ReactElement | React.ReactElement[]
}

/**
 * Root is expected to be the bottommost element of the app. 
 */
const Root = ({ children, config }: RootProps) => {
  const [kernel, setKernel] = useState(() => new Bootstrapper(config))
  useEffect(() => {
    const bootstrapper = new Bootstrapper(config)
    setKernel(bootstrapper)
    return bootstrapper.destructor
  }, [config])
  if (!kernel) return null
  return <Context.Provider value={kernel}>{children}</Context.Provider>
}

export default Root
export const useKernel = () => useContext(Context)
