import React from 'react';
import styled from 'styled-components';
import Decoration from './Decoration'

const Container = styled.footer` 
  text-align: center;
  margin: 2em auto;
  width: 96%;  
`

const Footer = () => <Container>  
  <Decoration>
    <h3><a href="mailto:christie@interweave.one">Reach out to me.</a></h3>
  </Decoration>
</Container>

export default Footer