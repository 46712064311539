import React from 'react';
import { useKernel } from './Root'
import qs from 'qs'
import { linkResolver } from './routingTables'

const PrismicPreview = ({ history, location }: any) => {
  const { prismic } = useKernel()
  React.useEffect(() => {  
    const {token, documentId}: any = qs.parse(location.search.slice(1));
    if (!token) return console.warn(`No token available, check your configuration`);    
    const resolver = prismic.getPreviewResolver(token, documentId)
    resolver.resolve(linkResolver, '/').then( url => history.push(url))         
  }, [history, location, prismic]);
  return null;
};

export default PrismicPreview