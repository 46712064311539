import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  html {
    background: #FBDCDA;    
    min-height: 100%;
    background-repeat: no-repeat;
  }

  body {
    
    font: 300 20px 'Zilla Slab', sans-serif;    

    @media (max-width: 480px) {
      font: 300 18px 'Zilla Slab', sans-serif;    
    }

    color: #20193C;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    box-sizing: border-box;
    padding-bottom: 9em;
  }

  a {
    transition: color 0.4s ease-in-out;
    color: inherit;    
    font-weight: 400;
    &:hover { color: white; }
  }
    
  h1 {  
    font-size: 1.2em;
    font-weight: 500;
  }
  h2 { 
    font-weight: 400;
    font-size: 1.1em;    
    margin: 3em 0 0.5em;
  }

  h3 {
    font-weight: 400;
    font-size: 1.1em;    
  }

  p {
    line-height: 1.6em;
    margin: 2em 0;
    hyphens: manual;
  }

  h2 + p {
    margin-top: 0.5em;
  }
`

export default GlobalStyle